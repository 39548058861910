export const CANCEL_REQUEST_MESSAGE = 'cancel request';

export const APP_ROUTES = {
    MAIN: '/',
    LOGIN: '/login',
    POST: '/story',
    STORY_ADD: '/story/add',
    REACTION: '/reaction',
    REACTION_ADD: '/reaction/add',
    PROFILE: '/profile',
    PROFILE_SETTINGS: '/profile/settings',
    PAGE404: '/404',
    PAGE403: '/403',
};

export const REACTION_EMPTY = {
    id: 0, 
    scope: 0, 
    story: {id: 0, title: ''}, 
    text_msg: '', 
    text_msg_translation: '', 
    language: {}, 
    user: {
        id: 0
    },
    like_you: false,
    like_count: "",
    text_status: "",
    text_status: ""
};