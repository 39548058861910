import React from "react";
import { Flex } from 'antd';
import { MdDehaze, MdOutlineFavoriteBorder, MdOutlineEdit, MdOutlineDeleteOutline, MdExitToApp } from "react-icons/md";
import ButtonShare from '../../Buttons/ButtonShare';
import { RiSendPlane2Line } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import './index.scss';

export default function ControlPanel({label, leftBtn, rightBtn, onLeftAction, onRightAction, position}) {
    const { t } = useTranslation();

    function LeftButton() {
        switch (leftBtn) {
          case 'pensil':
            return <MdOutlineEdit className="nav-icon" onClick={onLeftAction} />
          case 'delete':
            return <MdOutlineDeleteOutline className="nav-icon" onClick={onLeftAction} />
          default:
            return <MdOutlineFavoriteBorder className="nav-icon" onClick={onLeftAction} />
        }
    }

    function RightButton() {
        switch (rightBtn) {
          case 'send':
            return <RiSendPlane2Line className="nav-icon" onClick={onRightAction} />
          case 'share':
              return <ButtonShare/>
          case 'exit':
                return <MdExitToApp className="nav-icon" onClick={onRightAction} />
          default:
            return <MdDehaze className="nav-icon" onClick={onRightAction} />
        }
    }
	return (
		<Flex className={!!position ? "control-panel "+position : "control-panel"} justify="space-around" align="center" >
			<LeftButton/>
            { t(label) }
			<RightButton />
		</Flex>
	)
}