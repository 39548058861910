import { useState } from "react";
import config from '../utils/config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

// Pass URL
const useFetch = (service) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleLogin = async (response) => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL+"/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ credential: response.credential, service: service }),
    })
      .then((res) => {
        setLoading(false);

        return res.json();
      })
      .then((data) => {
        if (data?.user) {
          //localStorage.setItem("user", JSON.stringify(data?.user));
          //window.location.reload();
          localStorage.setItem(config.token, data.token);
          localStorage.setItem('user', JSON.stringify(data?.user));
          i18n.changeLanguage(data.user.lng_code);
          const urlParams = new URLSearchParams(window.location.search);
          const from = urlParams.get('from') ? urlParams.get('from') : '/';
          navigate(from);
        }

        throw new Error(data?.message || data);
      })
      .catch((error) => {
        setError(error?.message);
      });
  };
  return { loading, error, handleLogin };
};

export default useFetch;