import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Alert } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES, REACTION_EMPTY } from '../../utils/constant';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { MdMoreHoriz, MdExpandLess, MdOutlineTranslate, MdOutlineFavoriteBorder, MdFavorite } from "react-icons/md";
import PostUser from '../PostUser';
import UserReactionScope from '../UserReactionScope';
//import ControlPanel from '../WiwFooter/ControlPanel';
import ButtonShare from '../Buttons/ButtonShare';
import url from '../../utils/url';
import { getUser, formatNumberIntl, getLanguage } from '../../utils/func';
import api from '../../services';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const { reactionLike } = api;

export default function PostReaction(props) {
    const { reaction, finish, height, swiped, scope, onChangeSwipe } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleClick = () => {
      navigate(url(`/`), { replace: true });
    }
    let storageUser = getUser();
    const [height_content, setHeightContent] = useState('auto');
    const [display_content, setDisplayContent] = useState('flex');
    const [display_overflow, setDisplayOverflow] = useState('hidden');
    const [reaction_text, setReactionText] = useState(reaction.text_msg_translation);
    const [click_translate, setClickTranslate] = useState(false);
    const [reaction_likeYou, setReactionLikeYou] = useState(reaction.like_you);
    const [reaction_likeCount, setReactionLikeCount] = useState(reaction.like_count);
    const [read, setRead] = useState(swiped ? false : true);

    const ref_user = useRef(null);
    const ref_content = useRef(null);

    const getHeight = () => {
      let h = height-158;
      if(ref_user.current) {
        h = height-ref_user.current.clientHeight;
        h=h-50;
      }
      return h;
    }

    useEffect(() => {
      let display = 'flex';
      if(ref_content.current) {
        if (ref_content.current.scrollHeight > ref_content.current.clientHeight) {
          display = 'block';
        }
      }
      setDisplayContent(display);
    }, []);

    const readMore = ()=>{
      onChangeSwipe(false);
    }

    useEffect(() => {
      if (swiped) {
        setDisplayOverflow('hidden');
        setRead(false);
      } else {
        setRead(true);
        setDisplayOverflow('auto');
      }
    }, [swiped]);

    const readStop = ()=>{
      onChangeSwipe(true);
    }

    const like = async () => {
      setReactionLikeCount((reaction_likeYou) ? reaction_likeCount-1 : reaction_likeCount+1);
      setReactionLikeYou(!reaction_likeYou);
      await reactionLike({id: reaction.id});
    }

    const translate = ()=>{
      setReactionText((click_translate===true) ? reaction.text_msg_translation : reaction.text_msg);
      setClickTranslate(!click_translate);
    }

    const likeRender = ()=> {
      if(reaction_likeYou===true) {
        return <MdFavorite className="nav-icon is-like-icon" onClick={like} />
      }
      return <MdOutlineFavoriteBorder className="nav-icon" onClick={like} />
    }

    return (
      <div className="content-text">
        {finish ? (
          <div>
            <Link to={url(``)} replace={true}><BiBarChartAlt2 className="nav-icon" onClick={handleClick}/></Link>
            <BiBarChartAlt2 className="nav-icon" onClick={handleClick}/>
          </div>
          ) : (
          <div>
            <PostUser ref={ref_user} author={reaction.user} scope={scope} reactionId={reaction.id}/>
            <div className="reaction_content" ref={ref_content} style={{ height: getHeight()+'px', /*display: display_content,*/ overflowY: display_overflow }} >
              { reaction.text_status==='banned' &&
                <Alert message={ t("reaction.banned") } type="warning" showIcon closable />
              }
              { !!reaction_text && reaction_text!=='' ? parse(reaction_text) : <UserReactionScope size='big' scope = {reaction.scope} reactionId={reaction.id}/> }
            </div>
            
            <div className="control-panel-reaction">
              <Row align="middle">
                <Col span={8}>
                  <div className="control-panel-reaction-like">
                    {likeRender()}
                    {formatNumberIntl(reaction_likeCount)}
                  </div>
                </Col>
                <Col span={8} style={{display: 'flex', justifyContent: 'center'}}>
                  {display_content=='block' &&
                    <div>
                      {!read &&
                        <MdMoreHoriz className="nav-icon" onClick={readMore} />
                      }
                      {read &&
                        <MdExpandLess className="nav-icon" onClick={readStop}/>
                      }
                    </div>
                  }
                </Col>
                <Col span={8} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {reaction!== REACTION_EMPTY && reaction.language.code!==getLanguage() &&
                    <div className={`control-panel-reaction-translate ${click_translate ? 'active-translate' : ''}`} onClick={translate}>
                      {reaction.language.name}<MdOutlineTranslate className="nav-icon" style={{marginRight: '1em'}}/>
                    </div>
                  }
                  <ButtonShare/>
                </Col>
              </Row>
              <div className="control-panel-reaction-description">{ t("post.your_opinion") }</div>
            </div>
          </div>
        )}
      </div>
    )
};