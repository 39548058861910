import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { MdOutlineRateReview } from "react-icons/md";
import { APP_ROUTES } from '../../../utils/constant';
import url from '../../../utils/url';

export default function ButtonReactionAdd({ storyId, reactionId = null, disabled }) {
	const [src, setSrc] = useState(url(`${APP_ROUTES.POST}/${storyId}/add`));

	useEffect(() => {
		setSrc(!!reactionId ? url(`${APP_ROUTES.REACTION}/${reactionId}/edit`) : url(`${APP_ROUTES.POST}/${storyId}/add`));
	}, [storyId, reactionId]);

	return (
		<span>
		{!!disabled ?
			<MdOutlineRateReview className='nav-icon button-disable' />
			:
			<Link to={src}><MdOutlineRateReview className='nav-icon' /></Link>
		}
		</span>
	)
}