import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Layout, Flex, Select, Input, Upload, Radio, Image, Button } from 'antd';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import ButtonHome from '../../components/Buttons/ButtonHome';
import ButtonReactionAdd from '../../components/Buttons/ButtonReactionAdd';
import ButtonGlaz from '../../components/Buttons/ButtonGlaz';
import ButtonStoryAdd from '../../components/Buttons/ButtonStoryAdd';
import ButtonLoginOrProfile from '../../components/Buttons/ButtonLoginOrProfile';
import WiwHeaderTitle from '../../components/WiwHeaderTitle';
import UserImage from '../../components/UserImage';
import ControlButton from '../../components/WiwFooter/ControlButton';
//import ControlPanel from '../../components/WiwFooter/ControlPanel';
import { IoAtOutline } from "react-icons/io5";
import { MdExitToApp, MdOutlineDeleteOutline } from "react-icons/md";
import { getUser } from '../../utils/func';
import url from '../../utils/url';
import config from '../../utils/config';
import api from '../../services';
import { APP_ROUTES } from '../../utils/constant';
import { handleImageUpload } from '../../utils/func';

const { Header, Content, Footer } = Layout;
const { userByToken, userUpdateFields, getLng, getCountry, userLogout, userLogoutAll, userDelete } = api;

export default function Settings() {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	let storageUser = getUser();
	const [user, setUser] = useState({});
	const [user_name, setUserName] = useState(storageUser.user_name);
	const [first_name, setFirstname] = useState(storageUser.first_name);
	const [picture, setPicture] = useState(storageUser.picture);
	const [language, setLanguage] = useState(storageUser.lng_code);
	const [country, setCountry] = useState(storageUser.country_code);
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [lng_options, setLngOptions] = useState([]);
	const [country_options, setCountryOptions] = useState([]);
	const [country_dict, setCountryDict] = useState([]);
	const [height_content, setHeightContent] = useState(0);
  	const [margin_content, setMarginContent] = useState('0px');

	const ref_header= useRef(null);
	const ref_footer= useRef(null);

	function updateSize() {
		setTimeout(() => {
			setHeightContent(window.innerHeight-ref_header.current.clientHeight-ref_footer.current.clientHeight);
			setMarginContent(ref_header.current.clientHeight+'px');
		}, 100)
	}

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		function handleLoadData () {
			updateSize();
			loadSettings();
			loadLanguages();
			loadCountry();
		}
		handleLoadData();
	}, []);

	const loadSettings = async () => {
		let response = null;
		response = await userByToken();
		if (response.success) {
			if (response.user===null) {
				navigate(url(`${APP_ROUTES.PAGE403}`));
				return;
			}
			setUser(response.user);
			setUserName(response.user.user_name);
			setFirstname(response.user.first_name);
			setPicture(response.user.picture);
			setLanguage(response.user.lng_code);
			setCountry(response.user.country_code);
		}
	}

	const loadLanguages = async () => {
		let response = null;
		response = await getLng();
		if (response.success) {
			let options = [];
			response.list.forEach(l => {
				options.push({ label: l.button_text, value: l.lng_code });
			})
			setLngOptions(options);
		}
	}

	const loadCountry = async () => {
		let response = null;
		response = await getCountry();
		if (response.success) {
			setCountryDict(response.list);
		}
	}

	const changeUsername = (e)=>{
		setUserName(e.target.value);
	}

	const changeFirstname = (e)=>{
		setFirstname(e.target.value);
	}

	const changeLanguage = (e)=>{
		setLanguage(e.target.value);
	}

	const handleImage = async (e)=> {
		const base = await handleImageUpload(e);
		setPicture(base.data.link);
	}

	const changeCountry = (e)=>{
		setCountry(e);
	}

	useEffect(() => {
		checkDisabled();
 	}, [picture, language, first_name, user_name, language, country, user]);

	useEffect(() => {
		createOptionsCountry(language);
 	}, [country_dict, language]);

	useEffect(() => {
		i18n.changeLanguage(language);
 	}, [language]);

	const createOptionsCountry = (lng)=>{
		let options = [];
		country_dict.forEach(l => {
			options.push({ label: <span><Image preview={false} src={process.env.REACT_APP_CDN_IMG+process.env.REACT_APP_PATH_IMG_COUNTRY+l.code+'.png'}/> {l[lng]}</span>, value: l.code });
		})
		setCountryOptions(options);
	}

	const goBack = ()=>{
		navigate(-1);
	}

	const checkDisabled = () => {
		let d = true;
		if (user.user_name!==user_name) d = false;
		if (user.first_name!==first_name) d = false;
		if (user.picture!==picture) d = false;
		if (user.lng_code!==language) d = false;
		if (user.country_code!==country) d = false;
		setDisabledBtn(d);
	}

	const saveSettings = async ()=>{
		let data = {};
		if (user.first_name!==first_name) {
			data['first_name'] = first_name;
		}
		if (user.user_name!==user_name) {
			data['user_name'] = user_name;
		}
		if (user.picture!==picture) {
			data['picture'] = picture;
		}
		if (user.lng_code!==language) {
			data['lng_code'] = language;
		}
		if (user.country_code!==country) {
			data['country_code'] = country;
		}
		if (Object.keys(data).length === 0) {
			navigate(-1);
		} else {
			data['id'] = user.id;
			setDisabledBtn(true);
			let response = await userUpdateFields(data);
			if (response.success) {
				localStorage.setItem('user', JSON.stringify(response.user));
			}
		}
	}

	const exit = async (e, type=null) => {
		let response = (type===null) ? await userLogout() : await userLogoutAll();
		if (response.success) {
			localStorage.removeItem('user');
			localStorage.removeItem(config.token);
			navigate(url(`${APP_ROUTES.MAIN}`));
		}
	}

	const deleteAccount =  async ()=>{
		let response = await userDelete();
		if (response.success) {
			localStorage.removeItem('user');
			localStorage.removeItem(config.token);
			navigate(url(`${APP_ROUTES.MAIN}`));
		}
	}

  return (
    <Layout style={{height:"100vh"}}>
			<Helmet>
        <title>{t('user.settings')}</title>
        <meta name="description" content={t('user.settings')} />
      </Helmet>
			<Header className="header" ref={ref_header}>
				<WiwHeaderTitle title={t('user.settings')}></WiwHeaderTitle>
      </Header>
			<Content className="content-layot" style={{ height: height_content+'px', top: margin_content}}>
				<div className="profile-settings">
					<Input placeholder={t('user.placeholder_username')} value={user_name} prefix={<IoAtOutline />} className="wiw-input" onChange={changeUsername} />
					<Input placeholder={t('user.placeholder_name')} value={first_name} className="wiw-input" onChange={changeFirstname} />
					<Upload
						name="picture"
						className="wiw-input picture-input"
						showUploadList={false}
						action={handleImage}
						accept='image/gif,image/jpeg,image/jpg,image/png,image/svg'
					>
						<UserImage picture={picture}/>
					</Upload>
					<Select style={{ width: '100%' }} value={country} showSearch optionFilterProp="label" 
						options={country_options} filterOption={(inputValue, option) => option.label.props.children.join('').toLowerCase().includes(inputValue.toLowerCase())} 
						onChange={changeCountry}
						placeholder={t('user.placeholder_country')}/>

					<p style={{textAlign: 'center'}}>{t('user.language_news')} {language} {country}</p>

					<div className="lng-radio">
							<Radio.Group name="language" options={lng_options} value={language} optionType="button" onChange={changeLanguage} />
					</div>
					<Button type="text" icon={<MdExitToApp />} iconPosition='end' block onClick={(e)=>exit(e, null)} >
						{t('user.btn_exit')}
					</Button>
					<Button type="text" icon={<MdExitToApp />} iconPosition='end' block onClick={(e)=>exit(e, "all")} >
						{t('user.btn_exit_all')}
					</Button>
					<Button type="text" icon={<MdOutlineDeleteOutline />} iconPosition='end' block onClick={deleteAccount}>
						{t('user.btn_delete')}
					</Button>
				</div>
				
			</Content>
			<Footer ref={ref_footer}>
				{/*<ControlPanel label="user.save_settings" rightBtn='exit' position='relative' onRightAction={exit} leftBtn='delete' onLeftAction={deleteAccount}/>*/}
				<ControlButton onCancel={goBack} onOK={saveSettings} disabledOk={disabledBtn}/>
				<Flex justify="space-around" align="center" >
				<ButtonHome/>
				<ButtonStoryAdd/>
				<ButtonGlaz disabled/>
				<ButtonReactionAdd disabled></ButtonReactionAdd>
				<ButtonLoginOrProfile/>
				</Flex>
			</Footer>
    </Layout>
  )
}