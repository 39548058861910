/* eslint-disable import/no-anonymous-default-export */
export default {
  
    
    userLogin: 'POST /user/login',
    userLogout: '/user/logout',
    userLogoutAll: '/user/logout/all',
    userDelete: '/user/delete',

    userByToken: '/user',
    userById: '/user/id/:id',
    userByUsername: '/user/username/:username',
    userUpdateFields: 'PUT /user/:id',

  
    queryPost: '/post/:id',
    queryPostList: '/posts',
    storyList: 'POST /storys',
    storyStatistic: '/storys/statistic/:id',
    storyUsers: 'POST /storys/users',
    storyLoad: '/storys/:id',
    storyAdd: 'POST /storys/add',
    postLoad: 'POST /post/load',

    reactionLoad: '/reaction/:id',
    reactionsLoad: '/reactions',
    reactionsLoadRefresh: '/reactions/refresh',
    reactionLike: '/reaction/like/:id',
    reactionView: '/reaction/view/:id',
    reactionSaveVoting: 'POST /reaction/save',

    getLng: '/dict/language',
    getCountry: '/dict/country',
    

}