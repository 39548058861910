import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Reaction from './pages/reaction';
import Login from './pages/login';
import ReactionAdd from './pages/reaction_add';
import StoryAdd from './pages/story_add';
import Profile from './pages/profile';
import Page404 from './pages/404';
import Page403 from './pages/403';
import Settings from './pages/settings';
import { useTranslation } from "react-i18next";
import config from './utils/config';
import { APP_ROUTES } from './utils/constant';
import { initDB } from './utils/idb.ts';
import api from './services';
import {Helmet} from "react-helmet";
import { ConfigProvider } from 'antd';
//import Main from './pages/main'
//import Statistic from './pages/statistic'
//import Reaction from './pages/reaction'

const { userByToken } = api;

export default function App() {
  const [locale, setLocale] = useState('');
  const [load, setLoad] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    let language = window.location.pathname.split('/')[1];
    if (config.languages.indexOf(language)<0) {
      language = 'en'
    }
    i18n.changeLanguage(language);
    const locale = (language==='en') ? '' : '/'+language;
    setLocale(locale);
  }, [i18n]);

  useEffect(() => {
    async function handleInit () {
      await initDB();
      await loadSettings();
    }
    handleInit();
  },[]);

  const loadSettings = async () => {
    let response = null;
    let language = null;
    /*let lng_url = window.location.pathname.split('/')[1];
    if (config.languages.indexOf(lng_url)<0) {
      lng_url = 'en'
    }*/
    
    response = await userByToken();
    setLoad(false);
    if (response.success) {
      if (response.user!==null) {
        language = response.user.lng_code;
        /*if (lng_url !== language) {
          const loc = (language==='en') ? '' : '/'+language;
          const ll = window.location.pathname.replace(`/${lng_url}/`, loc);
          navigate(ll, { replace: true });
          return;
        }*/
      } else {
        language = window.location.pathname.split('/')[1];
        if (config.languages.indexOf(language)<0) {
          language = 'en'
        }
      }
    }
    i18n.changeLanguage(language);
    const locale = (language==='en') ? '' : '/'+language;
    setLocale(locale);
  }

  const routesItems = config.languages.map((l, index) =>
      <Routes key={index}>
          <Route path={`${l}${APP_ROUTES.MAIN}`} element={<Reaction/> } />
          <Route path={`${l}${APP_ROUTES.LOGIN}`} element={<Login />} />
          <Route path={`${l}${APP_ROUTES.POST}/:storyId`} element={<Reaction />} />
          <Route path={`${l}${APP_ROUTES.POST}/:storyId/:section`} element={<Reaction />} />
          <Route path={`${l}${APP_ROUTES.POST}/:storyId/:section`} element={<Reaction />} />
          <Route path={`${l}${APP_ROUTES.REACTION}/:reactionId`} element={<Reaction />} />
          <Route path={`${l}${APP_ROUTES.REACTION}/:reactionId/:section`} element={<Reaction />} />
          <Route path={`${l}${APP_ROUTES.REACTION_ADD}/:storyId`} element={<ReactionAdd />} />
          <Route path={`${l}${APP_ROUTES.STORY_ADD}`} element={<StoryAdd />} />
          <Route path={`${l}${APP_ROUTES.PROFILE}/:username`} element={<Profile />} />
          <Route path={`${l}${APP_ROUTES.PROFILE_SETTINGS}`} element={<Settings />} />
          <Route path={`${l}${APP_ROUTES.PAGE404}`} element={<Page404 />} />
          <Route path={`${l}${APP_ROUTES.PAGE403}`} element={<Page403 />} />
      </Routes>
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          activeBorderColor: '#000',
          activeShadow: 'none',
          hoverBorderColor: '#000',
          colorPrimary: '#000',
          borderRadius: '4px',
          lineWidth: '2px',
          colorBorder: '#000'
        },
        components: {
          Input: {
            hoverBorderColor: '#000',
            activeBorderColor: '#000',
            activeShadow: 'none',
          },
          Radio: {
            colorPrimaryBorder: '#000',
            buttonSolidCheckedBg: '#000'
          },
          Select: {
            singleItemHeightLG: 'auto'
          }
        }
      }}
    >
      <BrowserRouter>
        <Helmet>
          <title>WIW</title>
          <meta name="description" content="Who is Who" />
        </Helmet>
        {load ? <div></div> : routesItems}
      </BrowserRouter>
    </ConfigProvider>
  );
}
