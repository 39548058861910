import React, { useEffect, useState, useMemo } from "react";
import { Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constant';
import PostUser from '../PostUser';
import ControlPanel from '../WiwFooter/ControlPanel';
import { getUser } from '../../utils/func';
import url from '../../utils/url';
import api from '../../services';
import { useTranslation } from 'react-i18next';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { handleImageUpload, getPrivatFlg } from '../../utils/func';
import { SyncOutlined } from '@ant-design/icons';

const { reactionSaveVoting } = api;

export default function PostReactionAdd({storyId, reactionId, scope=4, reactionMessage, reactionStatus}) {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getUser();
  const [keyComment] = useState('comment'+storyId);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(reactionStatus);
  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  //console.log(htmlToDraftBlocks(reactionMessage));

  const [editorState, setEditorState] = useState((reactionMessage!=null) ? htmlToDraftBlocks(reactionMessage) : EditorState.createEmpty());
  const handleChange = (data) => {
    setEditorState(data);
  };
  
  let htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const toolbarOptions = {
    options: [/*"inline", "fontSize",*/ "image"],
    /*inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },*/
    image: {
      urlEnabled: false,
      uploadEnabled: true, 
      uploadCallback: handleImageUpload, 
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alignmentEnabled: false,
      defaultSize: {
        height: 'auto',
        width: '98%',
      },
    },
  };

  useEffect(() => {
    if (user === null) {
      navigate(url(`${APP_ROUTES.LOGIN}?from=${window.location.pathname}`), { replace: true });   
    }
    if (localStorage.getItem(keyComment)) {
      setEditorState(htmlToDraftBlocks(localStorage.getItem(keyComment)));
      setComment(localStorage.getItem(keyComment));
    }
  },[])

  const sendVoting = async () => {
    setLoading(true);
    const privat_flg = getPrivatFlg();
    const response = await reactionSaveVoting({story_id: Number(storyId), parent_reaction: reactionId, score: scope, txt_message: comment, privat: privat_flg});
    setLoading(false);
    if (response.statusCode === 401) {
      navigate(url(APP_ROUTES.LOGIN+'?from='+window.location.pathname), { replace: true });
      return;
    }
    localStorage.removeItem(keyComment);
    console.log(response);
    if (response.success) {
      navigate(url(APP_ROUTES.REACTION+'/'+response.id), { state: { update: true} });
      /*if (response.overall_status==='banned') {
        setStatus(response.text_status);
        setEditorState(htmlToDraftBlocks(response.text_msg));
      } else {
        navigate(url(APP_ROUTES.REACTION+'/'+response.id), { state: { update: true} });
      }*/
    }
  }

  const cancel = ()=>{
    navigate(-1)
  }

  useEffect(() => {
    setComment(htmlData);
    localStorage.setItem(keyComment, htmlData);
  },[editorState]);

  
  return (
    <div className="content-text">
      {
        loading &&
        <div className="story-add-loading">
          <SyncOutlined spin /> {t('reaction.checking')}
        </div>
		  }
      {user!==null && 
        <div className="addCommentPage">
          <PostUser author={user} scope={scope}/>
          { status==='banned' &&
            <Alert message={ t("reaction.banned") } type="warning" showIcon closable />
          }
          <Editor
            editorState={editorState}
            onEditorStateChange={handleChange}
            wrapperClassName="editor-wrapper"
            editorClassName="message-editor"
            toolbarClassName="message-toolbar"
            toolbar={toolbarOptions}
          />
        </div>
      }
      <ControlPanel label="post.opinion_news" leftBtn="pensil" rightBtn="send" onLeftAction={cancel} onRightAction={sendVoting} />
    </div>
  )
}