import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Flex, Col, Row, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdOutlineSearch, MdOutlineModeEditOutline, MdOutlineSaveAlt } from "react-icons/md";
import { Helmet } from "react-helmet";
import ButtonHome from '../../components/Buttons/ButtonHome';
import ButtonReactionAdd from '../../components/Buttons/ButtonReactionAdd';
import ButtonGlaz from '../../components/Buttons/ButtonGlaz';
import ButtonStoryAdd from '../../components/Buttons/ButtonStoryAdd';
import ButtonLoginOrProfile from '../../components/Buttons/ButtonLoginOrProfile';
import ButtonShare from '../../components/Buttons/ButtonShare';
import ButtonSettings from '../../components/Buttons/ButtonSettings';
import ButtonSubscribe from '../../components/Buttons/ButtonSubscribe';
import ButtonPushSubscribe from '../../components/Buttons/ButtonPushSubscribe';
import UserImage from '../../components/UserImage';
import StoryTitle from '../../components/SrotyTitle';
import { createName } from '../../utils/func';
import api from '../../services';
import { getUser } from '../../utils/func';
import { APP_ROUTES } from '../../utils/constant';

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { userByUsername, userUpdateFields, storyList } = api;

export default function Profile() {
	let storageUser = getUser();
	const [user, setUser] = useState({});
    let { username } = useParams();
	const [height_content, setHeightContent] = useState(0);
    const [margin_content, setMarginContent] = useState('0px');
	const [about, setAbout] = useState(null);
	const [editAbout, setEditAbout] = useState(false);
	const [reactions, setReactions] = useState([]);
	const [pageTitle, setPageTitle] = useState('');
	const [pageDescription, setPageDescription] = useState('');
	const { t } = useTranslation();
	const navigate = useNavigate();

	const ref_header= useRef(null);
    const ref_footer= useRef(null);

	function updateSize() {
      setTimeout(() => {
        setHeightContent(window.innerHeight-ref_header.current.clientHeight-ref_footer.current.clientHeight);
        setMarginContent(ref_header.current.clientHeight+'px');
      }, 100)
    }

    useLayoutEffect(() => {
      window.addEventListener('resize', updateSize);
      return () => window.removeEventListener('resize', updateSize);
    }, []);

	useEffect(() => {
      async function handleLoadUser () {
        await loadUser();
      }
      handleLoadUser();
    }, [username]);

	const loadUser = async () => {
      let response = null;
      response = await userByUsername({username: username});
      if (response.success) {
			if (response.user===null) {
				navigate(APP_ROUTES.PAGE404, { replace: true });
				return;
			} 
				updateSize();
				setUser(response.user);
				setAbout(response.user.about);
				const reactionsQuery = await storyList({user_id: response.user.id});
				if (reactionsQuery.success) {
					setReactions(reactionsQuery.list);
				}
		}
	}

	/**
	* Формирование title, meta=description
	*/
	useEffect(() => {
		let title = `${t('user.profile_title')} ${createName(user)} @${user.user_name}`;
		setPageTitle(title);
		let description = `${t('user.profile_description', { user: createName(user)+' @'+user.user_name })}`;
		setPageDescription(description);
    }, [user]);

	const changeVoting = (scope) => {
      //setAddScope(scope);
    }

	const searchOpen = () => {
      //setAddScope(scope);
    }

	const onChangeAbout = (e) => {
		setAbout(e.target.value);
	}

	const startEditAbout = () => {
		setEditAbout(true);
	}

	const endEditAbout = async () => {
		await userUpdateFields({id: user.id, about: about});
		setEditAbout(false);
	}

	function ButtonProfile() {
		if (!!storageUser && storageUser.id===user.id) {
			return <>
				{editAbout ? (
						<MdOutlineSaveAlt className="nav-icon" onClick={endEditAbout}/>
					) : (
						<MdOutlineModeEditOutline className="nav-icon" onClick={startEditAbout}/>
				)}
				<ButtonSettings/>
			</>
		} else {
			return <>
				<ButtonPushSubscribe/>
				<ButtonSubscribe/>
			</>
		}
	}

	const reactionItems = reactions.map((reaction, index) =>
      <StoryTitle key={index} storyId={reaction.story.id} title={reaction.story.title} 
		link={true} msg={reaction.text_msg} scope={reaction.scope} fill={false} reactionId={reaction.id}/>
    );

    return (
      <Layout style={{height:"100vh"}}>
				<Helmet>
					<title>{pageTitle}</title>
					<meta name="description" content={pageDescription} />
				</Helmet>
        <Header className="header" ref={ref_header}>
					<div className="wiwHeader">
							<Row gutter={8} align="middle">
									<Col className="align-center" span={4}>
											<Button onClick={searchOpen} icon={<MdOutlineSearch />}/>
									</Col>
									<Col className="align-center" span={20}>
											<div className="pageName">{username}</div>
									</Col>
							</Row>
							<Row className="profile-user-control">
								<Col className="align-left" span={4}>
									<UserImage picture={user.picture}/>
								</Col>
								<Col className="align-right" span={20}>
									<Flex className="profile-user-control-button" justify="flex-end" align="center">
										<ButtonProfile/>
										<ButtonShare/>
									</Flex>
								</Col>
							</Row>
							<Row>
								<Col className="profile-user-name">
									{createName(user)}
								</Col>
							</Row>
					</div>
        </Header>
        <Content className="content-layot" style={{ height: height_content+'px', top: margin_content}}>
					<div className="profile-bio">
						{editAbout ? (
							<TextArea className="profileAbout" autoSize value={about} placeholder={t('post.add_comment_text')} onChange={onChangeAbout}/>
						) : (
							<>{about}</>
						)}
					</div>
					<div>
						{reactionItems}
					</div>
        </Content>
        <Footer ref={ref_footer}>
            <Flex justify="space-around" align="center" >
                <ButtonHome/>
                <ButtonStoryAdd/>
                <ButtonGlaz disabled/>
                <ButtonReactionAdd disabled></ButtonReactionAdd>
                <ButtonLoginOrProfile/>
            </Flex>
        </Footer>
      </Layout>
    )
}